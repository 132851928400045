<template>
  <div class="item-box" v-loading="showLoading">
<!--    <div class="item-cont"-->
<!--         :contenteditable="true"-->
<!--         @focus="focusItem"-->
<!--      @blur="blurItem">-->
    <div class="item-cont"
         @click="focusItem">
<!--      <div v-html="fragment.list[showNum]"></div>-->
      <el-input type="textarea"
                ref="TextArea"
                v-if="showContent"
                class="text-area"
                placeholder="请输入内容"
                v-model="fragment.list[showNum]"></el-input>
      <div v-else class="item-cont-txt" v-html="fragment.list[showNum]"></div>

      <div class="item-text-list" v-disable-select v-if="aiBtnShow">
        <div class="item-text-btn" v-for="val in AiText" @click="modifyTxt(val)">
          <span class="l">{{val.name}}</span>
          <span class="r">{{val.txt}}</span>
        </div>
      </div>
    </div>
    <div class="foot-box">
      <div class="foot-item">
        <div class="show-btn" @click="caretSwitch('left')" v-if="showNum > 0"><i class="el-icon-caret-left"></i></div>
        <div class="col-btn" v-else><i class="el-icon-caret-left"></i></div>
        <div class="foot-cont">
          第
          <span class="s">{{ showNum + 1 }}</span>
          <span v-if="fragment.list.length !== 1">/{{fragment.list.length}}</span>
          版
        </div>
        <div class="show-btn" @click="caretSwitch('right')" v-if="showNum < (fragment.list.length - 1)"><i class="el-icon-caret-right"></i></div>
        <div class="col-btn" v-else><i class="el-icon-caret-right"></i></div>
      </div>
      <span class="foot-tip">{{ fragment.describe[showNum] }}</span>
      <div class="foot-btn" v-if="showContent">
<!--        <el-button size="mini" @click="closeContent">取消</el-button>-->
        <el-button type="primary" size="mini" @click="blurItem">确认修改</el-button>
      </div>
      <div class="foot-delete" v-if="fragment.list.length > 1 && showNum !== 0" @click="itemDelete"><i class="el-icon-delete"></i></div>
    </div>
  </div>
</template>
<script>
import RewriteApi from '@/api/rewrite'
export default {
  props: ['item','indexNum'],
  components: {},
  data() {
    return {
      fragment: {
        list: [],
        describe: []
      },
      showNum: 0,
      showLoading: false,
      AiText: [{
        type: 1, //1、极限降重 2、温柔降重 3、专业降重 4、学术润⾊ 5、扩写
        name: '极限降重',
        txt: '最大限度改头换面，且保证通顺'
      },{
        type: 2,
        name: '温柔降重',
        txt: '细致调整，保留原文风格语感'
      },{
        type: 3,
        name: '专业降重',
        txt: '避开专业词汇，只改写其他部分'
      },{
        type: 4,
        name: '学术润色',
        txt: '提升语言表达，添加文采魅力'
      },{
        type: 5,
        name: '扩写',
        txt: '丰富现有内容，增加论文深度'
      }],
      aiBtnShow: false,
      showContent: false,
      textCont: '', // 记录修改前
    }
  },
  created() {
    this.fragment.list[0] = JSON.parse(JSON.stringify(this.item))
    this.fragment.describe[0] = '原版'
  },
  methods: {
    caretSwitch(type){
      if(type === 'left'){
        this.showNum -= 1
      }else if(type === 'right'){
        this.showNum += 1
      }
      this.sendingEmit()
    },
    itemDelete(){
      let delNum = this.showNum
      this.fragment.list.splice(delNum,1)
      this.fragment.describe.splice(delNum,1)
      this.showNum -= 1
      this.sendingEmit()
    },
    focusItem(){
      // console.log('获得焦点')
      if(this.showContent === true) return
      this.showContent = true
      // this.textCont = JSON.parse(JSON.stringify(this.fragment.list[this.showNum]))
      // console.log('获得焦点=',this.textCont)
      setTimeout(()=> {
        this.$refs.TextArea.$refs.textarea.focus();
      },200)
      this.aiBtnShow = true
    },
    blurItem(){
      // console.log('失去焦点')
      // let fl = JSON.parse(JSON.stringify(this.fragment.list[this.showNum]))
      // let listCont = JSON.stringify(fl).slice(1, -1)
      // this.fragment.list[this.showNum] = listCont
      this.showContent = false
      this.aiBtnShow = false
      this.sendingEmit()
    },
    closeContent(){
      this.showContent = false
      this.aiBtnShow = false
      // console.log('取消=',this.textCont)
      // if(!this.textCont){
      //   this.fragment.list[this.showNum] = JSON.parse(JSON.stringify(this.textCont))
      //   this.textCont = ''
      // }
    },
    modifyTxt(val){
      if(this.showLoading === true) return
      this.showLoading = true
      if(this.showNum >= 9){
        this.showLoading = false
        return this.$message.warning('版本已达到储存上限，请修改或删除后再操作！')
      }
      // this.closeContent()
      let name = '该版本已使用：' + val.name
      let orderId = localStorage.getItem('orderId')
      // 处理 AI 生成
      // AI 返回的结果
      let data = {
        content: this.fragment.list[this.showNum],
        type: val.type,
        orderId: orderId
      }
      this.postRewriteCcontent(data,name)
      // let texts = val.name
      // try {
      //   this.fragment.list.push(texts)
      //   this.fragment.describe.push(name)
      //   this.showNum += 1
      // }catch(err){
      //   console.log(err)
      // }
    },
    postRewriteCcontent(data,name){
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = _this.$md5(setOf)
      RewriteApi.postRewriteCcontent(data, key, time, infoMd5).then(res => {
        _this.fragment.list.push(res.data)
        _this.fragment.describe.push(name)
        _this.showNum += 1
        _this.showLoading = false
        _this.$emit('getTodayNumber', true)
        // console.log('list+num=',_this.fragment.list,_this.showNum)
      }).catch(() => {
        _this.showLoading = false
      })
    },
    sendingEmit(){
      // console.log('list+num=',this.fragment.list,this.showNum)
      let msg = {
        list: this.fragment.list[this.showNum],
        listIndex: this.indexNum
      }
      this.$emit('modifyContent', msg)
    }
  }
}
</script>
<style scoped lang="less">
.item-box{
  .item-cont{
    position: relative;
    color: #4a4a4a;
    .text-area{
      /deep/ textarea{
        height: 560px !important;
      }
    }
    .item-cont-txt{
      padding: 10px;
      line-height: 24px;
      white-space: pre-line;
    }
    .item-text-list{
      position: absolute;
      z-index: 9;
      top: 0;
      right: -400px;
      width: 360px;
      padding: 20px;
      border-radius: 15px;
      background-color: #fcfdff;
      box-shadow: 0 0 6px rgba(0, 0, 0, .1);
      .item-text-btn{
        padding: 12px 20px;
        margin-bottom: 10px;
        border-radius: 10px;
        background-color: #fcfdff;
        box-shadow: 0 0 3px rgba(0, 0, 0, .1);
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        span{
          font-size: 14px;
        }
        span.l{
          width: 80px;
          color: #589cff;
        }
        span.r{
          color: #9ea2a9;
        }
      }
      .item-text-btn:last-child{
        margin-bottom: 0;
      }
    }
    .item-text-list::before{
      content: '';
      position: absolute;
      top: 20px;
      left: -40px;
      width: 40px;
      height: 2px;
      background-color: #409eff;
    }
  }
  [contenteditable] {
    outline: none;
    border: 2px solid #f4f4f4 !important;
    border-radius: 15px;
  }
  [contenteditable]:focus {
    border: 2px solid #409eff !important;
    border-radius: 15px;
    background-color: #ffffff;
  }
  .foot-box{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .foot-item{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .show-btn{
        width: 20px;
        height: 20px;
        cursor: pointer;
        i{
          font-size: 20px;
          color: #409eff;
        }
      }
      .col-btn{
        width: 20px;
        height: 20px;
        cursor: pointer;
        i{
          font-size: 20px;
          color: #d7d7d7;
        }
      }
      .foot-cont{
        padding: 0 10px;
        line-height: 20px;
        color: #666666;
        span.s{
          padding: 0 5px;
          color: #8ab9ff;
        }
      }
    }
    .foot-tip{
      margin-left: 30px;
      line-height: 20px;
      color: #9ea2a9;
    }
    .foot-btn{
      margin-left: auto;
    }
    .foot-delete{
      margin-left: auto;
      width: 20px;
      height: 20px;
      cursor: pointer;
      i{
        font-size: 18px;
        color: #666666;
      }
    }
  }
}
@media screen and (max-width: 900px){
  .item-box{
    .foot-box{
      padding-top: 10px;
      .foot-item{
        .foot-cont{
          padding: 0 5px;
          font-size: 11px;
          span.s{
            padding: 0 2px;
          }
        }
      }
      .foot-tip{
        width: 22%;
        margin-left: 10px;
        font-size: 11px;
      }
      .foot-btn{
        margin-left: auto;
      }
      .foot-delete{
        margin-left: auto;
        width: 20px;
        height: 20px;
        cursor: pointer;
        i{
          font-size: 18px;
          color: #666666;
        }
      }
    }
  }
}
</style>
