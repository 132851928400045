import request from '@/utils/request'

export default {
  // 单段修改(需要TICKET)
  postRewriteCcontent(data,key,time,md5){
    return request({
      url: '/rewrite/content',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
  // 获取正文JSON(需要TICKET)
  getRewriteJson(orderId,key,time,md5){
    return request({
      url: '/rewrite/getContentJson/' + orderId,
      method: 'get',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      }
    })
  },
  // 保存下载(需要TICKET)
  postRewriteSave(data,key,time,md5){
    return request({
      url: '/rewrite/save',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
  // 获取订单今日无限改稿额度信息
  getRewriteLimit(orderId){
    return request({
      url: '/rewrite/getRewriteLimit/' + orderId,
      method: 'get'
    })
  }
}
